
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import PeopleFind from "../../../components/People/people-find.vue";
class PageImportacionnRequest extends PageRequest {

  motivo: string
  descripcion: string
}
@Component({
  components: {    
    PeopleFind,
  }
})

export default class Importaciones extends AbpBase{
  pagerequest: PageImportacionnRequest = new PageImportacionnRequest()
  creationTime: Date[] = []
  createModalShow: boolean = false
  editModalShow: boolean = false
  pagination: any = {}
  findModalShow: boolean = false

 get list() {
     
    return this.$store.state.importacion.list
  }
  get loading() {
    return this.$store.state.importacion.loading
  }
    get pageSize() {
    return this.$store.state.importacion.pageSize
  }
  get totalCount() {
    return this.$store.state.importacion.totalCount
  }
  get currentPage() {
    return this.$store.state.importacion.currentPage
  }
  set currentPage(page) {
    this.$store.commit('importacion/setCurrentPage', page)
    this.getpage()
  }
  async find() {
    this.findModalShow = true
  }
  async create() {
      
    this.createModalShow = true
    this.pagerequest = new PageImportacionnRequest()
  }
 
  getSearch = async (model) => {
    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value
      } else {
        delete this.pagerequest[element.fieldBind]
      }
    })
    
    await this.getpage()
    this.findModalShow = false
  }

  async created() {
    this.getpage()
  }
  async getpage() {
    
    this.pagerequest.maxResultCount = this.pageSize
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize

    await this.$store.dispatch({
      type: 'importacion/getAll',
      data: this.pagerequest
    })
    const pagination = { ...this.pagination }
    pagination.total = this.$store.state.importacion.totalCount
    pagination.pageSize = this.$store.state.importacion.pageSize
    pagination.current = this.$store.state.importacion.currentPage
    this.pagination = pagination
  }
  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit('importacion/setCurrentPage', pagination.current)
    this.getpage()

    await this.getpage()
  }
   edit() {
    this.editModalShow = true
  }
  hide() {
    this.findModalShow = false
  }
  stateColorScheme(state: boolean) {
    if(state){
        return "ant-tag-success";
    }
    else{
      return "ant-tag-danger";
    }
  
  }

  columns = [
    {
      title: this.L('Origen'),
      dataIndex: 'origen',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    {
      title: this.L('Fichero'),
      dataIndex: 'fichero',
      thClass: 'w-auto',
      tdClass: 'w-auto'
      
    },
    {
      title: this.L('Observaciones'),
      dataIndex: 'observaciones',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    
      {
      title: this.L('Tarea'),
      dataIndex: 'tarea',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
     {
       
      title: this.L('Completada'),
      dataIndex: 'completada',
      scopedSlots: { customRender : "completada"},
      
      thClass: 'w-auto',
      tdClass: 'w-auto',
      
    },
   
    {
      title: this.L('Fecha Creacion'),
      dataIndex: 'creationTime',
      scopedSlots: { customRender: "date" },
    },  
  ];
  searchDefinition: {}[] = [
    {
      fieldName: 'Origen',
      fieldType: 'text',
      fieldBind: 'origen',
      value: ''
    },
    {
      fieldName: 'Fichero',
      fieldType: 'text',
      fieldBind: 'fichero',
      value: ''
    },
  
  ]
}



